<template>
    <main class="flex-1 overflow-y-auto">
        <section aria-labelledby="primary-heading"
                 class="min-w-0 flex-1 h-full flex flex-col lg:order-last">
            <div class="p-6 print:p-0 min-h-full">
                <c-menu v-if="menuCategories.length > 0">
                    <!-- first page -->
                    <c-menu-page v-if="cover">
                        <img src="@/assets/logo-full-color.svg"
                             alt="Mauros Logo"
                             class="w-60 m-auto">
                    </c-menu-page>

                    <c-menu-page v-for="categories in generatePages">
                        <div class="menu-category"
                             v-for="(category, categoryIndex) in categories">
                            <div class="w-full mb-6">
                                <h2 class="text-3xl font-bold uppercase text-mauros-red text-center w-full">
                                    {{ getCategoryById(category).name_de }}</h2>
                                <p v-if="getCategoryById(category).desc_de !== ''" class="text-xl font-semibold w-8/12 text-center mx-auto text-mauros-green mt-2">
                                    {{ getCategoryById(category).desc_de }}</p>
                            </div>
                            <div v-for="(product, productIndex) in getProductsByCategoryId(category)"
                                 class="menu-product">
                                <div class="menu-product__number">
                                    {{ product.number }}
                                </div>
                                <div class="menu-product__info">
                                    <div class="flex">
                                        <div class="menu-product__name">
                                            <span class="badge" v-if="product.isNew">NEU</span>
                                            {{ product.name_de }}
                                            <img v-if="product.isVegan"
                                                 class="vegan"
                                                 src="../assets/vegan.svg"
                                                 alt="Ist Vegan">
                                        </div>
                                        <div class="menu-product__ingredients">
                                            <span class="menu-product__ingredient"
                                                  v-for="(ingredient) in product.ingredientsSubjectToLabeling">
                                                {{ getIngredientById(ingredient).label }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="menu-product__desc" v-if="product.desc_de !== ''">
                                        {{ product.desc_de }}
                                    </div>
                                </div>
                                <div class="menu-product__quantity">
                                    {{ product.quantity }}
                                </div>
                                <div class="menu-product__price">
                                    {{ productPrice(product.price) }} €
                                </div>
                            </div>
                        </div>
                    </c-menu-page>


                    <!-- last page -->
                    <c-menu-page v-if="cover">
                        <img src="@/assets/logo-full-color.svg"
                             alt="Mauros Logo"
                             class="mt-8 mb-24 w-28 mx-auto">

                        <div>
                            <div class="text-center mb-24">
                                <h4 class="font-bold uppercase text-mauros-red">Feiern bei Mauros</h4>
                                <p>
                                    Sie planen eine Veranstaltung und brauchen eine Location?
                                </p>
                                <p>
                                    Feiern Sie bei Mauros! Ganz egal zu welchem Anlass (Hochzeit, Geburtstag,
                                    Firmenveranstaltungen usw.)
                                </p>
                                <p>
                                    Sprechen Sie uns einfach an oder klingeln Sie durch:
                                    <strong>Telefon 06233 778 738 0</strong>
                                </p>
                            </div>
                            <h4 class="font-bold uppercase text-mauros-red mb-4 text-center">Kennzeichnungspflichtige
                                                                                             Stoffe</h4>

                            <div class="max-w-4xl mx-auto">
                                <div
                                    class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-5 print:grid-cols-5 border-t-2 mb-6">
                                    <div class="border-b-2 flex text-xs py-2"
                                         v-for="ingredient in ingredientsSubjectToLabeling">
                                        <span class="font-semibold mr-2 text-right"
                                              style="min-width: 20px;">
                                            {{ ingredient.label }}
                                        </span>
                                        <span>
                                            {{ ingredient.name_de }}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="font-bold text-mauros-green text-center mb-40">
                                Vegan
                            </div>

                            <div class="text-center">
                                <p class="font-bold">
                                    Informationen über Allergene
                                </p>
                                <p>
                                    Fragen Sie den Service nach unserer Allergentabelle oder scannen Sie den QR-Code, um
                                    sich die Inhalte auf Ihrem Smartphone anzusehen.
                                </p>
                                <p>
                                    Die Grammangaben dieser Karte beziehen sich auf die Rohgewichte. Alle Preisangaben
                                    in € inklusive MwSt.
                                </p>
                            </div>
                        </div>
                    </c-menu-page>
                </c-menu>
            </div>
        </section>
    </main>

    <aside class="hidden w-96 bg-white border-l border-gray-200 overflow-y-auto lg:block">
        <div class="flex-1 overflow-y-auto h-full">
            <template v-if="isNew || isEdit">
                <!-- new -->
                <form @submit.prevent="isNew ? handleAdd : handleEdit">
                    <PageHead sticky
                              :title="isNew ? 'Neue Karte' : `Karte - ${menuNameOg}`">
                        <template #prefix>
                            <c-button variant="back"
                                      class="mr-2"
                                      @click.native.prevent="back">
                                <component :is="backIcon"
                                           class="w-5" />
                            </c-button>
                        </template>
                        <template #actions>
                            <c-button v-if="isNew"
                                      :disabled="noChange"
                                      @click.native.prevent="handleAdd">Speichern
                            </c-button>
                            <c-button v-if="isEdit"
                                      :disabled="noChange"
                                      @click.native.prevent="handleEdit">Anwenden
                            </c-button>
                        </template>
                    </PageHead>
                    <div>
                        <div class="pt-4 px-4">
                            <label for="name_new"
                                   class="block text-sm font-medium text-gray-700">
                                Name
                            </label>
                            <input type="text"
                                   required
                                   v-model="menuName"
                                   name="name_new"
                                   id="name_new"
                                   class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm" />
                        </div>
                        <div class="p-4">
                            <SwitchGroup as="div" class="flex items-center justify-between">
                                <Switch v-model="cover"
                                        :class="[cover ? 'bg-mauros-green-600' : 'bg-gray-200', 'mr-3 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mauros-green-500']">
                                <span aria-hidden="true"
                                      :class="[cover ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']"/>
                                </Switch>
                                <span class="flex-grow flex flex-col">
                              <SwitchLabel as="span" class="text-sm font-medium text-gray-900"
                                           passive>Deckblätter</SwitchLabel>
                              <SwitchDescription as="span"
                                                 class="text-sm text-gray-500">Ein- & ausblenden der Deckblättern</SwitchDescription>
                            </span>
                            </SwitchGroup>
                        </div>
                        <draggable :list="menuCategories"
                                   class="divide-y divide-gray-200"
                                   item-key="id"
                                   @end="validate">
                            <template #item="{element}">
                                <div class="relative px-4 py-3 flex items-center hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-500">
                                    <input type="checkbox"
                                           class="mr-2"
                                           v-model="element.visible">
                                    <div :class="{['opacity-30 line-through']: !!element.enabled}">
                                        {{ getCategoryById(element.category).name_de }}
                                    </div>
                                    <component :is="dragIcon"
                                               class="cursor-move ml-auto w-5 text-gray-400" />
                                </div>
                            </template>
                        </draggable>
                    </div>
                </form>
            </template>
            <template v-else>
                <div>
                    <!-- overview -->
                    <PageHead sticky
                              title="Karten">
                        <template #actions>
                            <c-button @click.native.prevent="prepareNew">Neu</c-button>
                        </template>
                    </PageHead>
                    <div v-for="m in menus"
                         @click="prepareEdit(m.id)"
                         class="relative px-4 py-3 flex items-center space-x-3 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-500">
                        {{ m.name }}
                        <!--                    <pre>{{m}}</pre>-->
                    </div>
                </div>
            </template>


        </div>
    </aside>
</template>

<script>
import draggable from 'vuedraggable';
import CMenu from '@/components/CMenu';
import CMenuPage from '@/components/CMenuPage';
import {mapActions, mapGetters, mapState} from 'vuex';
import {diff} from 'deep-object-diff';
import {ArrowLeftIcon, ViewGridIcon} from '@heroicons/vue/outline';
import {Switch, SwitchDescription, SwitchGroup, SwitchLabel} from '@headlessui/vue';

export default {
    name: 'MenuGenerator',
    components: {
        draggable,
        CMenu,
        CMenuPage,
        Switch,
        SwitchGroup,
        SwitchLabel,
        SwitchDescription,
    },
    data() {
        return {
            isNew: false,
            isEdit: false,
            categoriesConfig: [],
            menuId: '',
            menuName: '',
            menuNameOg: '',
            cover: true,
            menuCategories: [],
            menuCategoriesOg: [],
        };
    },
    methods: {
        ...mapActions('menus', ['addMenu', 'editMenu']),
        back() {
            this.menuName = JSON.parse(JSON.stringify(this.menuNameOg));
            this.menuCategories = [];
            this.isNew = false;
            this.isEdit = false;
            this.menuId = '';
        },
        prepareNew() {
            const categories = JSON.parse(JSON.stringify(this.categories));

            this.isNew = true;
            this.menuName = '';
            this.menuNameOg = '';
            this.menuCategories = categories.map(function (item, index) {
                return {category: item.id, order: index, visible: false};
            });
            this.menuCategoriesOg = JSON.parse(JSON.stringify(this.menuCategories));
        },
        prepareEdit(menuId) {
            const menu = this.getMenuById(menuId);

            this.isEdit = true;
            this.menuId = menu.id;
            this.menuName = menu.name;
            this.menuNameOg = menu.name;
            this.menuCategories = JSON.parse(JSON.stringify(menu.items));
            this.menuCategoriesOg = JSON.parse(JSON.stringify(menu.items));
        },
        async handleAdd() {
            const add = await this.addMenu({name: this.menuName, items: this.menuCategories});
            if (add.status === 200) {
                this.isNew = false;
            }
        },
        async handleEdit() {
            const edit = await this.editMenu({
                id: this.menuId,
                menu: {
                    name: this.menuName,
                    items: this.menuCategories,
                },
            });
            this.menuNameOg = this.menuName;
            this.menuCategoriesOg = JSON.parse(JSON.stringify(this.menuCategories));
        },
        productPrice(price) {
            const p = price && typeof price === 'number' ? price.toFixed(2) : 0;
            return p.replace('.', ',');
        },
        validate() {
            return Object.keys(diff(this.menuNameOg, this.menuName)).length <= 0 &&
                JSON.stringify(this.menuCategoriesOg) === JSON.stringify(this.menuCategories);
        },
    },
    computed: {
        ...mapState('products', ['categories']),
        ...mapGetters('menus', ['getMenus', 'getMenuById']),
        ...mapGetters('products', ['getIngredientsSubjectToLabeling', 'getCategories', 'getProductsByCategoryId', 'getCategoryById', 'getIngredientById']),
        generatePages() {
            // get necessary information
            const information = this.menuCategories.map(cat => {
                return {
                    id: cat.category,
                    category: this.getCategoryById(cat.category),
                    products: this.getProductsByCategoryId(cat.category),
                    visible: cat.visible,
                }
            });


            const paperHeight = 1042; // unit: px
            const paperSpacer = 40; // unit: px
            // const categoryHeight =


            const limit = 27;
            let counter = 0;
            let categories = [];
            const ret = [];

            // iterate trough every category and product to evaluate the maximum amount of data within one page
            information.forEach(info => {
                if (info.visible) {
                    let categoryWithDescription = info.category.desc_de !== '' ? 3 : 1;
                    categoryWithDescription = String(info.category.desc_de).length >= 45 ? 4 : categoryWithDescription;
                    const productsWithDescription = info.products.map(product => product.desc_de !== '').filter(Boolean).length;
                    if (counter + info.products.length + productsWithDescription + categoryWithDescription < limit) {
                        categories.push(info.id);
                        counter += info.products.length + productsWithDescription + categoryWithDescription;
                    } else {
                        ret.push(categories);
                        categories = [];
                        counter = 0;
                        categories.push(info.id);
                        counter += info.products.length + productsWithDescription + categoryWithDescription;
                    }
                }
            });

            return ret;
        },
        menus() {
            return this.getMenus();
        },
        ingredientsSubjectToLabeling() {
            return this.getIngredientsSubjectToLabeling();
        },
        categories() {
            return this.getCategories();
        },
        noChange() {
            return this.validate();
        },
        backIcon() {
            return ArrowLeftIcon;
        },
        dragIcon() {
            return ViewGridIcon;
        },
    },
};
</script>
